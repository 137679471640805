import React, { Component } from 'react';

class NotFound extends Component {
  render() {
    return (
      <div className="row no-gutters justify-content-center">
        <div className="col-12 col-sm-7 col-md-5 col-lg-4 col-xl-4">
          <div
            className="container no-gutters no-side-padding"
            style={{ background: '#fff', height: '100vh', display: 'table' }}
          >
            <div
              className="row no-gutters justify-content-center"
              style={{ display: 'table-cell', verticalAlign: 'middle' }}
            >
              <div style={{ textAlign: 'center' }}>
                <p
                  style={{
                    fontSize: 35,
                    lineHeight: 1.3,
                    marginBottom: '1rem',
                  }}
                >
                  404
                </p>
                <img src="../../static/images/icons/one21/404ilustration.svg" />
                <p
                  style={{
                    fontSize: 30,
                    marginTop: '1rem',
                  }}
                >
                  Page Not Found
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
